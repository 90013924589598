<template>
    <div class="Login">
        <FrameContainer layout="hm" asidewidth="300px" headerheight="62px" > 
            <div slot="header" class="header">     
                <div class="headercontent">
                    <div style="float:left;display:flex;padding:5px;padding-left:20px;height:48px;width:100%;">
                        <div style="min-width:48px;height:48px;">
                            <el-image fit="scale-down" :src='require("@/images/48/dku.00002.png")' style="width:100%;height:100%;"/>
                        </div>
                        <div style="height:48px;min-width:100px;text-align:center;margin-top:5px;color:white;">
                            <div style="line-height:24px;width:100%;text-align:center;">
                                <span style="font-size:16px">德数云健康</span>  
                            </div>  
                            <div style="height:12px;line-height:12px;font-size:12px;width:100%;">
                                <span>dkusoft</span>
                            </div>                                    
                        </div>    
                    </div>
                    <div style="float:right;min-width:400px;">
                        <div style="float:right;font-size:18px;color:white;min-width:450px;">
                            <el-menu :default-active="activeindex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                            <el-menu-item index="1">健康管理</el-menu-item>
                            <el-menu-item index="2">体检中心</el-menu-item>
                            <el-menu-item index="3">数据交换平台</el-menu-item>
                            <el-menu-item index="4">关于我们</el-menu-item>
                            </el-menu>
                        </div>    
                    </div>
                </div>
            </div>
            <div slot="main" class="main"> 
                <Health v-if="currentkey=='1'"/>
                <Examine v-if="currentkey=='2'"/>
                <DataExchange v-if="currentkey=='3'"/>
                <About v-else/>
            </div>
        </FrameContainer>
    </div>
</template>

<script>    
    import FrameContainer from "@/components/base/frame/FrameContainer";    
    import Health from "@/views/home/Health";   
    import Examine from "@/views/examine/Examine";   
    import DataExchange from "@/views/dataexchange/DataExchange";
    import About from "@/views/about/About";
    export default {            
        //##############################################################################################################################
        components:
        {
            FrameContainer,Health,Examine,DataExchange,About
        },    
        //############################################################################################################################## 
        data(){
            return{   
               activeindex:"1",
               currentkey:'1'
            }
        }, 
        //##############################################################################################################################
        methods:
        {
            handleSelect(key, keyPath) 
            {
                this.currentkey=key;
                console.log(key, keyPath);
            }
                        
        },   
    }
</script>

<style lang="scss" scoped>
    @import '@/style/handle.scss';
    .Login {
        width: 100%;            
        height: 100%;            
        overflow: hidden;                    
        background-color: #0072C6;
    }
    .header1{
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 下方阴影 */
    }
    .headercontent{
        width: 1100px;        
        margin-left:auto;
        margin-right:auto;  
        display: flex; 
        // background-color: blue;      
    }
    .main{
        height:100%;
        height:calc(100vh - 62px);        
    }
</style>

<style>

    .el-menu--horizontal>.el-submenu .el-submenu__title {
        height:35px !important;
        line-height:35px !important;    
        width: 100%;          
        background-color: transparent !important;
        color:black !important;
    }
    .el-menu-item {
        font-size: 16px;
        color:white !important;
        padding: 0 20px;
        cursor: pointer;
        transition: border-color .3s, background-color .3s, color .3s;
        box-sizing: border-box;
    }
    .el-menu-item:focus, .el-menu-item:hover {
        outline: 0;
        background-color: #ecf5ff;
        background-color: transparent !important;
        color:yellow !important;
    }
    .el-menu--horizontal>.el-menu-item.is-active {
        /* border-bottom: 2px solid #037ffc; */
        border-bottom: 2px solid yellow;
        background-color: transparent !important;
        color:yellow !important;        
    }
    .el-menu.el-menu--horizontal {
        border-bottom: solid 0px #e6e6e6;
        background-color: transparent !important;
        color:white !important;
        width: 100% !important;
    }
    .el-submenu.is-active .el-submenu__title {
        border-bottom: 0px !important;
        color:white !important;
        background-color: transparent !important;
    }

    .el-menu.is-active .el-menu__title {
        border-bottom: 0px !important;
        background-color: red !important;
        color:white !important;
    }

    .el-menu--collapse .el-menu .el-submenu, .el-menu--popup{
        min-width: 220px!important;
        color:white !important;
    }

</style>